@import '~antd/dist/antd.less';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.clear::after {
    content: '';
    clear: both;
    height: 0;
}
.ant-layout {
    background-color: transparent !important;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 5px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0em;
    margin-block-end: 0em;
}
#root {
    height: 100%;
    position: relative;
}

.ant-btn-primary{
    background:#00A5DC !important;
}
// .ant-table table {
//     border-collapse: collapse;
// }

// .ant-table-column-sorters {
//   padding: 0;
// }

// .ant-input, .ant-btn, 
// .ant-select-selector, 
// .ant-picker, 
// .ant-input-number {
//     border-radius: 5px !important;
// }
// .ant-table-thead > tr > th,
// .ant-table-tbody > tr > td,
// .ant-table tfoot > tr > th,
// .ant-table tfoot > tr > td {
//     padding: 0 8px;
//     height: 39px;
//     border: 1px solid #ebeef5;
//     font-size: 13px;
// }
// .ant-table-thead > tr > th {
//     background-color: #f2f2f2;
//     color: #333;
// }
// // .ant-table-body {
// //     overflow-y: auto !important;
// // }
// // .ant-table-body table{
// //     overflow: hidden;
// // }
// .ant-table-tbody > tr.ant-table-row:hover > td {
//     background-color: #eef4f8;
// }
// .ant-table-tbody > tr > td {
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
//     color: #606266;
// }
// .ant-table-tbody > tr:nth-child(2n) > td {
//     background-color: #fff;
// }
// .ant-table-tbody > tr:nth-child(2n+1) > td {
//   background-color: #fafafa;
// }


// .ant-input, .ant-btn, .ant-select-selector {
//   border-radius: 3px!important;
// }

// .ant-btn {
//   height: 30px;
//   line-height: 1;
// }

// .ant-btn-sm {
//   height: 28px;
//   line-height: 1;
//   font-size: 13px;
// }
// .ant-form-item {
//   margin-bottom: 10px;
// }
// .ant-modal-header {
//   height: 40px;
//   line-height: 40px;
//   background-color: #f2f2f2;
//   padding: 0;
//   padding-left: 16px !important;
//   border: 0;
// }
// .ant-modal-title {
//   line-height: 40px;
// }
// .ant-modal-close-x {
//   height: 40px;
//   width: 40px;
//   line-height: 40px;
// }
// .ant-table-thead .react-resizable {
//   position: relative;
// }

// .react-resizable .react-resizable-handle {
//   position: absolute;
//   width: 15px;
//   height: 100%;
//   bottom: 0;
//   right: 0;
//   cursor: col-resize;
//   z-index: 1;
// }
// .ant-picker {
//   width: 100%;
// }
// .ant-menu-dark .ant-menu-inline.ant-menu-sub {
//   background: #263340;
// }
// .ant-layout-sider {
//   background: #2F4050;
// }
// .ant-menu.ant-menu-dark {
//   background: #2F4050;
// }
// .ant-modal-body {
//   padding: 20px;
//   padding-bottom: 0;
// }